























































































































import {Component, Prop, Vue} from 'vue-property-decorator';
import User from '@/models/User';
import {namespace} from 'vuex-class';
import ActionMenuComponent from '@/components/shared/table/ActionMenu.component.vue';
import UserInitialsConfig from '@/misc/UserInitialsConfig';
import TimeSchedule from '@/models/TimeSchedule';
import {Permission} from '@/misc/Enums/permission.enum';
import {userStoreActions, userStoreMutations} from '@/stores/user.store';
import {getAllWeekDays} from '@/misc/Enums/WeekDay';
import {authStoreGetter} from '@/stores/auth.store';


const UserStore = namespace('user');
const AuthStore = namespace('auth');

/**
 * Simple table component that lists all users in a table
 */
@Component({
  components: {
    ActionMenuComponent,
    UserInitialsComponent: () => import(
        /* webpackChunkName: "UserInitialsComponent" */
        '@/components/user/UserInitials.component.vue'),
    UserManageComponent: () => import(
        /* webpackChunkName: "UserManageComponent" */
        '@/components/user/UserManage.component.vue'),
    TableComponent: () => import(
        '@/components/shared/table/Table.component.vue'),
  },
})
export default class UserTableComponent extends Vue {

  @UserStore.Mutation(userStoreMutations.STORE_ACTIVE_USER)
  private activeUserMutation!: (user: User) => any;
  @UserStore.Action(userStoreActions.LOAD_USERS_ACTION)
  private loadUsersAction!: (payload: { tenantId: string, relations?: string[] }) => Promise<User[]>;
  @UserStore.Action(userStoreActions.LOAD_USER_ACTION)
  private loadUserAction!: (tenantId: string) => Promise<User>;
  @UserStore.Action(userStoreActions.DELETE_USER_ACTION)
  public deleteUserAction!: (user: User) => Promise<User>;
  @UserStore.Action(userStoreActions.SET_USER_STATUS_ACTION)
  private setUserStatusAction!: (payload: { id: string, active: boolean, preview?: boolean, force?: boolean }) => Promise<User>;
  @AuthStore.Getter(authStoreGetter.USER)
  public _activeUser!: User;

  public get activeUser(): User {
    return this._activeUser;
  }

  private showDeleteUserDialog: boolean = false;
  private showChangeStatusUserDialog: boolean = false;

  private showUserDeactivationDialog: boolean = false;
  private selectedUser?: User;
  @Prop({default: []})
  public users!: User[];

  @Prop({default: ''})
  private searchString: string = '';

  private userConfig: UserInitialsConfig = {
    showFullName: false,
    showTooltip: false,
    big: true,
  };

  constructor() {
    super();
  }

  public isActive() {
    const inactive: string = (this.$t('GENERAL.INACTIVE') as string).toLowerCase();
    const active: string = (this.$t('GENERAL.ACTIVE') as string).toLowerCase();
    console.log(this.selectedUser);
    return {
      status: this.selectedUser!.active ? inactive : active,
    };
  }

  get tableHeaders(): any[] {
    return [
      {text: this.$t('GENERAL.NAME').toString(), value: 'name', width: '20%'}, // -5
      {text: this.$t('GENERAL.ROLE').toString(), value: 'role', width: '15%'}, // -5
      {text: this.$t('GENERAL.PERSONNEL_NUMBER').toString(), value: 'personnelNumber', width: '20%'},
      {text: this.$t('GENERAL.PHONE').toString(), value: 'phone', width: '14%'},
      {text: this.$t('GENERAL.EMAIL').toString(), value: 'email', width: '17%'}, // -5
      {text: this.$t('GENERAL.STATUS').toString(), value: 'active', width: '9%', class: 'justify-center'},
      {text: '', value: 'actions', width: '5%', class: 'justify-start'},
    ];
  }

  public onRowClicked(user: User) {
    this.$router.push({
      name: 'userDetails', params: {
        tenantId: this.$route.params.tenantId,
        userId: user.id!,
      },
    });
  }

  private getColor(color: string): string {
    return this.$colorHandler.getThemeColor(color);
  }

  /**
   * User delete functions
   */
  public onUserDeleteClick(user: User) {
    this.showDeleteUserDialog = true;
    this.selectedUser = user;
  }

  public async onUserDelete(user: User) {
    this.showDeleteUserDialog = false;
    try {
      await this.deleteUserAction(user);
      this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.SUCCESS');
      await this.loadUsersAction({tenantId: this.$route.params.tenantId});
    } catch (e) {
      switch (e) {
        case e.status === 409: {
          this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.409');
          break;
        }
        default: {
          this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.USER_DELETE.ERROR');
        }
      }
    }
  }

  public async onUserEditClick(user: User) {
    this.selectedUser = await this.loadUserAction(user.id!);
    this.$emit('editUser', this.selectedUser);
  }

  /**
   * Methods for deactivating Employees
   */
  public onUserStatusToggleClick(updatedUser: User) {
    this.showChangeStatusUserDialog = true;
    this.selectedUser = updatedUser;
  }

  public onUserStatusChange(updatedUser: User) {
    this.showChangeStatusUserDialog = false;
    if (updatedUser.active === true) {
      this.showUserDeactivationDialog = true;
    } else {
      this.changeUserStatus(updatedUser);
    }
  }

  public async changeUserStatus(updatedUser: User) {
    this.showUserDeactivationDialog = false;
    const params = {
      id: updatedUser.id!,
      active: !updatedUser.active!,
      force: true,
    };
    try {
      await this.setUserStatusAction(params);

      updatedUser.active ?
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_DEACTIVATE') :
          this.$notifySuccessSimplified('USER_MANAGE.NOTIFICATIONS.USER_ACTIVATE');
    } catch (error) {
      // Reset Activate State to False, because some error happened
      updatedUser.active = false;

      // Show Correct error Message
      if (error.status === 402) {
        this.$notifyErrorSimplified('USER_MANAGE.NOTIFICATIONS.QUOTA_EXCEEDED');
      } else {
        this.$notifyErrorSimplified('GENERAL.NOTIFICATIONS.GENERAL_ERROR');
      }
    } finally {
      await this.loadUsersAction({tenantId: this.$route.params.tenantId});
    }
  }
}
